import { createFeature, createReducer, createSelector, Store } from '@ngrx/store';
import {
  ContourLca,
  LcaOutputType,
  LcaValueType,
  OutputDTO,
  OutRealEstateDTO,
  RealEstateDTO,
  RebuildEstateDTO,
  RebuildEstimationOutputDTO,
  WithdrawDTO,
} from '@generated/generatedEntities';
import { immerOn } from 'ngrx-immer/store';
import { RealEstateActions } from '@app/core/services/store/real-estate.actions';
import { inject } from '@angular/core';
import { IframeInput, LcaBuildingElementPresetDTO, PLOT_TYPE } from '@generated/extended';
import _ from 'lodash';

export const realEstatesFeatureKey = 'realEstate';

export enum CALCULATION_STATE {
  INIT = 'INIT',
  NOT_CALCULATED = 'NOT_CALCULATED',
  LOADING = 'LOADING',
  READY = 'READY',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
  CALLING = 'CALLING',
  CALCULATING = 'CALCULATING',
  FINAL_RESULT_LOADED = 'FINAL_RESULT_LOADED',
}

export interface RealEstateState {
  /**
   * This represents the baseline from the backend. **When working with the form this object WILL NOT be modified.**
   */
  initialRealEstate: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO | IframeInput;
  /**
   * Contains updates made by the user during the form process. **Caution** - this object is not always up to date with the actual form state.
   */
  realEstateProgress: RealEstateDTO | RebuildEstateDTO | OutRealEstateDTO | IframeInput;
  status: CALCULATION_STATE;
  estimationOutput: RebuildEstimationOutputDTO;
  withdrawDTO: WithdrawDTO;
  plots: { [P in PLOT_TYPE]: string | null }; //strings in this map are images base64 encoded!
  greyEnergy: { [P in LcaValueType]?: number };

  presets: LcaBuildingElementPresetDTO;
}

export const initialState: RealEstateState = {
  initialRealEstate: {} as OutRealEstateDTO,
  realEstateProgress: {} as OutRealEstateDTO,
  status: CALCULATION_STATE.INIT,
  estimationOutput: {} as RebuildEstimationOutputDTO,
  withdrawDTO: {} as WithdrawDTO,
  plots: { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null },
  greyEnergy: {},
  presets: { uValues: {}, data: {}, elements: {} } as LcaBuildingElementPresetDTO,
};

function getSum(data: { [P in LcaValueType]?: number } | null): undefined | number {
  if (!data) {
    return undefined;
  }
  return Object.values(data).reduce((a, b) => a + b, 0);
}

function aggregateGreyEnergyData(data: { [P in LcaOutputType]?: { [P in LcaValueType]?: number } }) {
  const getSum: (data: { [P in LcaOutputType]?: { [P in LcaValueType]?: number } }, keyId: LcaValueType) => number = (
    data: { [P in LcaOutputType]?: { [P in LcaValueType]?: number } },
    keyId: string,
  ) => {
    return Object.entries(data)
      .filter(([key, value]) => key !== 'OPERATION')
      .flatMap(([key, value]) => Object.entries(value))
      .filter(([key2, value2]) => key2 === keyId)
      .reduce((acc, [, value2]) => acc + (value2 as number), 0);
  };

  return {
    CO_2_TOTAL: getSum(data, 'CO_2_TOTAL'),
    CO_2_TOTAL_EBF: getSum(data, 'CO_2_TOTAL_EBF'),
    CO_2_TOTAL_EOL: getSum(data, 'CO_2_TOTAL_EOL'),
    CO_2_TOTAL_EBF_EOL: getSum(data, 'CO_2_TOTAL_EBF_EOL'),
    ENERGY_NON_RENEWABLE_TOTAL: getSum(data, 'ENERGY_NON_RENEWABLE_TOTAL'),
    ENERGY_NON_RENEWABLE_TOTAL_EBF: getSum(data, 'ENERGY_NON_RENEWABLE_TOTAL_EBF'),
    ENERGY_NON_RENEWABLE_TOTAL_EOL: getSum(data, 'ENERGY_NON_RENEWABLE_TOTAL_EOL'),
    ENERGY_NON_RENEWABLE_TOTAL_EBF_EOL: getSum(data, 'ENERGY_NON_RENEWABLE_TOTAL_EBF_EOL'),
    ENERGY_RENEWABLE_TOTAL: getSum(data, 'ENERGY_RENEWABLE_TOTAL'),
    ENERGY_RENEWABLE_TOTAL_EBF: getSum(data, 'ENERGY_RENEWABLE_TOTAL_EBF'),
    ENERGY_RENEWABLE_TOTAL_EOL: getSum(data, 'ENERGY_RENEWABLE_TOTAL_EOL'),
    ENERGY_RENEWABLE_TOTAL_EBF_EOL: getSum(data, 'ENERGY_RENEWABLE_TOTAL_EBF_EOL'),
  };
}

const reducer = createReducer(
  initialState,
  immerOn(RealEstateActions.updateRealEstate, (draft: RealEstateState, data) => {
    draft.initialRealEstate = { ...data };
    draft.realEstateProgress = { ...data };
    draft.status = CALCULATION_STATE.LOADED;
  }),
  immerOn(RealEstateActions.updateProgressOnRealEstate, (draft: RealEstateState, data) => {
    draft.realEstateProgress = { ...data };
  }),
  immerOn(RealEstateActions.resetStore, (draft: RealEstateState, data) => {
    // draft.initialRealEstate = {} as OutRealEstateDTO;
    // draft.realEstateProgress = {} as OutRealEstateDTO;
    // draft.status = CALCULATION_STATE.INIT;
    // draft.estimationOutput = {} as RebuildEstimationOutputDTO;
    // draft.withdrawDTO = {} as WithdrawDTO;
    // draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    // draft.greyEnergy = {};
    // console.log('resetStore');
    draft = { ...initialState };
  }),
  immerOn(RealEstateActions.loadRealEstate, (draft, data) => {
    // realEstate: draft.realEstate,
    draft.status = CALCULATION_STATE.LOADING;
  }),
  immerOn(RealEstateActions.getEmptyRealEstate, (draft, data) => {
    // realEstate: draft.realEstate,
    draft.status = CALCULATION_STATE.LOADING;
  }),
  immerOn(RealEstateActions.noopDueToStatus, (draft, data) => {
    console.log('noopDueToStatus');
  }),

  immerOn(RealEstateActions.loadRealEstateSuccess, (draft: RealEstateState, data) => {
    draft.initialRealEstate = { ...data } as OutRealEstateDTO;
    draft.realEstateProgress = { ...data };
    draft.status = CALCULATION_STATE.LOADED;
  }),

  immerOn(RealEstateActions.loadRealEstateFailure, (draft: RealEstateState, data) => {
    draft.initialRealEstate = {} as OutRealEstateDTO;
    draft.status = CALCULATION_STATE.ERROR;
  }),

  immerOn(RealEstateActions.estimateLCACosts, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.CALCULATING;
    draft.realEstateProgress = data.data;
  }),

  immerOn(RealEstateActions.estimateLCAEnergyPerformanceFailure, (draft: RealEstateState, data) => {
    console.warn(data);
  }),

  immerOn(RealEstateActions.presetLCA, (draft: RealEstateState, data) => {
    draft.realEstateProgress = data.data;
  }),

  immerOn(RealEstateActions.presetLCASuccess, (draft: RealEstateState, data) => {
    // console.log(
    //   `presetLCASuccess ${_.isEqual(draft.realEstateProgress.quality.facadeTypes[0].facadeType, data.data.facadeType?.facadeType)} ${draft.realEstateProgress.quality.facadeTypes[0].facadeType} vs ${data.data.facadeType?.facadeType}`,
    // );
    if (
      draft.realEstateProgress.quality.heatingContainers.length > 0 &&
      draft.realEstateProgress.quality.heatingContainers[0].heatingType == null
    ) {
      draft.realEstateProgress.quality.heatingContainers[0].heatingType = 'HEAT_PUMP_AIR';
      if (draft.realEstateProgress.quality.heatingContainers[0].distribution == null) {
        draft.realEstateProgress.quality.heatingContainers[0].distribution = 'WUE_2';
      }
    }

    if (_.isEqual(draft.realEstateProgress.quality.facadeTypes[0].facadeType, data.data.facadeType?.facadeType)) {
      function normalizeArrayLength<T>(targetArray: T[], sourceArray: T[]) {
        while (targetArray.length < sourceArray.length) {
          targetArray.push({} as T);
        }
        while (targetArray.length > sourceArray.length) {
          targetArray.pop();
        }
      }

      function updateValues(
        state: ContourLca,
        newState: ContourLca,
        previousPreset: ContourLca | undefined | null,
        previousPresetUValue: number | null | undefined,
        currentPresetUValue?: number | null | undefined,
      ) {
        if (state.base == null || previousPreset?.base == state.base) {
          state.base = newState.base;
        }
        if (state.isolation == null || previousPreset?.isolation == state.isolation) {
          state.isolation = newState.isolation;
        }
        if (state.surface == null || previousPreset?.surface == state.surface) {
          state.surface = newState.surface;
        }
        // console.warn(target.base, target.orientation, previousPreset?.orientation, source.orientation);
        if (
          (state.orientation == null && newState.orientation != null) ||
          previousPreset?.orientation == state.orientation
        ) {
          state.orientation = newState.orientation;
        }
        if (state.uvaluePlaceholder == null || previousPresetUValue == state.uvaluePlaceholder) {
          state.uvaluePlaceholder = currentPresetUValue;
        }
      }

      if (draft.realEstateProgress.quality.groundFloorSlabs.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.groundFloorSlabs.length; i++) {
          if (data.elements?.groundFloorSlabs) {
            updateValues(
              draft.realEstateProgress.quality.groundFloorSlabs[i],
              data.elements.groundFloorSlabs,
              draft.presets.elements.groundFloorSlabs,
              draft.presets.uValues.groundFloorSlabs,
              data.uValues.groundFloorSlabs,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain.length > 0) {
        if (data.data.exteriorWallsCladdingAboveTerrain) {
          normalizeArrayLength(
            draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain,
            data.data.exteriorWallsCladdingAboveTerrain,
          );
        }
        for (let i = 0; i < draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain.length; i++) {
          if (data.elements.exteriorWallsCladdingAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain[i],
              data.elements.exteriorWallsCladdingAboveTerrain,
              draft.presets.elements.exteriorWallsCladdingAboveTerrain,
              draft.presets.uValues.exteriorWallsCladdingAboveTerrain,
              data.uValues.exteriorWallsCladdingAboveTerrain,
            );
            if (
              data.data.exteriorWallsCladdingAboveTerrain != null &&
              data.data.exteriorWallsCladdingAboveTerrain[i].orientation
            ) {
              draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain[i].orientation =
                data.data.exteriorWallsCladdingAboveTerrain[i].orientation;
            }
          }
        }
      }

      if (draft.realEstateProgress.quality.exteriorWallsCladdingUnderTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.exteriorWallsCladdingUnderTerrain.length; i++) {
          if (data.elements.exteriorWallsCladdingUnderTerrain) {
            updateValues(
              draft.realEstateProgress.quality.exteriorWallsCladdingUnderTerrain[i],
              data.elements.exteriorWallsCladdingUnderTerrain,
              draft.presets.elements.exteriorWallsCladdingUnderTerrain,
              draft.presets.uValues.exteriorWallsCladdingUnderTerrain,
              data.uValues.exteriorWallsCladdingUnderTerrain,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.interiorWallsAboveTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.interiorWallsAboveTerrain.length; i++) {
          if (data.elements.interiorWallsAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.interiorWallsAboveTerrain[i],
              data.elements.interiorWallsAboveTerrain,
              draft.presets.elements.interiorWallsAboveTerrain,
              null,
              null,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.ceilingsAboveTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.ceilingsAboveTerrain.length; i++) {
          if (data.elements.ceilingsAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.ceilingsAboveTerrain[i],
              data.elements.ceilingsAboveTerrain,
              draft.presets.elements.ceilingsAboveTerrain,
              null,
              null,
            );
          }
        }
      }
      if (draft.realEstateProgress.quality.ceilingsUnderTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.ceilingsUnderTerrain.length; i++) {
          if (data.elements.ceilingsUnderTerrain) {
            updateValues(
              draft.realEstateProgress.quality.ceilingsUnderTerrain[i],
              data.elements.ceilingsUnderTerrain,
              draft.presets.elements.ceilingsUnderTerrain,
              null,
              null,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.flatRoofsAboveTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.flatRoofsAboveTerrain.length; i++) {
          if (data.elements.flatRoofsAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.flatRoofsAboveTerrain[i],
              data.elements.flatRoofsAboveTerrain,
              draft.presets.elements.flatRoofsAboveTerrain,
              draft.presets.uValues.flatRoofsAboveTerrain,
              data.uValues.flatRoofsAboveTerrain,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.inclinedRoofsAboveTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.inclinedRoofsAboveTerrain.length; i++) {
          if (data.elements.inclinedRoofsAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.inclinedRoofsAboveTerrain[i],
              data.elements.inclinedRoofsAboveTerrain,
              draft.presets.elements.inclinedRoofsAboveTerrain,
              draft.presets.uValues.inclinedRoofsAboveTerrain,
              data.uValues.inclinedRoofsAboveTerrain,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.roofsUnderTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.roofsUnderTerrain.length; i++) {
          if (data.elements.roofsUnderTerrain) {
            updateValues(
              draft.realEstateProgress.quality.roofsUnderTerrain[i],
              data.elements.roofsUnderTerrain,
              draft.presets.elements.roofsUnderTerrain,
              draft.presets.uValues.roofsUnderTerrain,
              data.uValues.roofsUnderTerrain,
            );
          }
        }
      }

      if (draft.realEstateProgress.quality.windowsAboveTerrain.length > 0) {
        if (data.data.windowsAboveTerrain) {
          normalizeArrayLength(
            draft.realEstateProgress.quality.windowsAboveTerrain as ContourLca[],
            draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain as ContourLca[],
          );
        }
        for (let i = 0; i < draft.realEstateProgress.quality.windowsAboveTerrain.length; i++) {
          if (data.elements.windowsAboveTerrain) {
            updateValues(
              draft.realEstateProgress.quality.windowsAboveTerrain[i],
              data.elements.windowsAboveTerrain,
              draft.presets.elements.windowsAboveTerrain,
              draft.realEstateProgress.quality.windowsAboveTerrain[i].uvaluePlaceholder,
              data.data.windowsAboveTerrain![i].uvaluePlaceholder,
            );
            //we match orientation of the facade above terrain.
            draft.realEstateProgress.quality.windowsAboveTerrain[i].orientation =
              draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain[i].orientation;
          }
        }
      }

      if (draft.realEstateProgress.quality.windowsUnderTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.windowsUnderTerrain.length; i++) {
          if (data.elements.windowsUnderTerrain) {
            updateValues(
              draft.realEstateProgress.quality.windowsUnderTerrain[i],
              data.elements.windowsUnderTerrain,
              draft.presets.elements.windowsUnderTerrain,
              draft.presets.uValues.windowsUnderTerrain,
              data.data.windowsUnderTerrain![i].uvaluePlaceholder,
            );
          }
        }
      }
      if (draft.realEstateProgress.quality.interiorWallsUnderTerrain.length > 0) {
        for (let i = 0; i < draft.realEstateProgress.quality.interiorWallsUnderTerrain.length; i++) {
          if (data.elements.interiorWallsUnderTerrain) {
            updateValues(
              draft.realEstateProgress.quality.interiorWallsUnderTerrain[i],
              data.elements.interiorWallsUnderTerrain,
              draft.presets.elements.interiorWallsUnderTerrain,
              null,
              null,
            );
          }
        }
      }
    }

    draft.presets = data;
  }),

  immerOn(RealEstateActions.presetLCAFunctionalUnits, (draft: RealEstateState, data) => {
    draft.realEstateProgress = data.data;
  }),
  immerOn(RealEstateActions.presetLCAFunctionalUnitsSuccess, (draft: RealEstateState, data) => {
    for (let i = 0; i < data.data.length; i++) {
      const value = data.data[i];
      // console.warn(`reduces fu success ${draft.realEstateProgress.usages[i].type == value.type}, ${value.numFuUsage}`);
      if (draft.realEstateProgress.usages[i].type == value.type) {
        //data is from API - we have only numFuUsage there.
        draft.realEstateProgress.usages[i].numFuUsagePlaceholder = value.numFuUsage;
      }
    }
  }),

  /*  immerOn(RealEstateActions.presetUValueLCA, (draft: RealEstateState, data) => {
    // console.log(
    //   'presetUValueLCA',
    //   data.data.quality.windowsAboveTerrain.map((x) => x.isolation),
    // );
    draft.realEstateProgress = data.data;
  }),*/

  immerOn(RealEstateActions.presetLCAHPPower, (draft: RealEstateState, data) => {
    // console.log('presetLCAHPPower', data.data);
    draft.realEstateProgress = data.data;
  }),
  immerOn(RealEstateActions.presetLCAHPPowerSuccess, (draft: RealEstateState, data) => {
    // console.log('presetLCAHPPower', data.data);
    //to calculate the effective value it would be as following:
    // data.data[0] / 100 * 7 = HP power in kW.
    draft.realEstateProgress.energy.current.heatPowerPlaceholder = data.data[0];
  }),

  /* immerOn(RealEstateActions.presetUValueLCASuccess, (draft: RealEstateState, data) => {
    console.log('presetUValueLCASuccess', data);
    // draft.presets.uValue;

    draft.presets.uValues = data.uValues;

    draft.realEstateProgress.quality.groundFloorSlabs.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.groundFloorSlabs;
    });

    draft.realEstateProgress.quality.exteriorWallsCladdingAboveTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.exteriorWallsCladdingAboveTerrain;
    });
    draft.realEstateProgress.quality.exteriorWallsCladdingUnderTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.exteriorWallsCladdingUnderTerrain;
    });

    draft.realEstateProgress.quality.ceilingsUnderTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.ceilingsUnderTerrain;
    });
    draft.realEstateProgress.quality.flatRoofsAboveTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.flatRoofsAboveTerrain;
    });
    draft.realEstateProgress.quality.inclinedRoofsAboveTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.inclinedRoofsAboveTerrain;
    });
    draft.realEstateProgress.quality.roofsUnderTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.roofsUnderTerrain;
    });
    draft.realEstateProgress.quality.windowsAboveTerrain.forEach((value) => {
      value.uvaluePlaceholder = data.uValues!.windowsAboveTerrain;
    });
    draft.realEstateProgress.quality.windowsUnderTerrain.forEach((value, index) => {
      if (
        draft.realEstateProgress.quality.windowsUnderTerrain[index] != null &&
        data.data!.windowsUnderTerrain![index] != null
      ) {
        value.uvaluePlaceholder = data.data!.windowsUnderTerrain![index].uvaluePlaceholder;
      }
    });
  }),*/

  immerOn(RealEstateActions.estimateLCACostsSuccess, (draft: RealEstateState, data) => {
    // if (draft.estimationOutput == null) {
    draft.status = CALCULATION_STATE.FINAL_RESULT_LOADED;
    draft.estimationOutput = { lcaOutput: {} } as RebuildEstimationOutputDTO;
    draft.estimationOutput = { lcaOutputPlot: {} } as RebuildEstimationOutputDTO;
    // } else {
    // draft.withdrawDTO = data;
    draft.withdrawDTO = {} as WithdrawDTO;
    draft.withdrawDTO.outputDTO = {} as OutputDTO;
    draft.withdrawDTO.outputDTO.lcaOutput = { ...data.outputDTO.lcaOutput };
    draft.withdrawDTO.outputDTO.lcaOutputPlot = { ...data.outputDTO.lcaOutputPlot };
    draft.withdrawDTO.outputDTO.NK = { ...data.outputDTO.NK };
    draft.withdrawDTO.outputDTO.EK = { ...data.outputDTO.EK };
    draft.withdrawDTO.outputDTO.VK = { ...data.outputDTO.VK };
    draft.withdrawDTO.outputDTO.costs = { ...data.outputDTO.costs };
    draft.withdrawDTO.outputDTO.lccCosts = data.outputDTO.lccCosts != null ? { ...data.outputDTO.lccCosts } : null;
    draft.withdrawDTO.outputDTO.insuranceCost = { ...data.outputDTO.insuranceCost };
    draft.withdrawDTO.outputDTO.smallBuildingCost = { ...data.outputDTO.smallBuildingCost };
    draft.withdrawDTO.outputDTO.isPomCosts = { ...data.outputDTO.isPomCosts };
    draft.withdrawDTO.outputDTO.pomCosts = { ...data.outputDTO.pomCosts };
    draft.withdrawDTO.outputDTO.times = { ...data.outputDTO.times };
    draft.withdrawDTO.realEstateId = data.realEstateId;
    draft.withdrawDTO.pdfId = data.pdfId;
    draft.withdrawDTO.pdfIdOfReleaseCandidate = data.pdfIdOfReleaseCandidate;
    draft.withdrawDTO.rebuildReplacementBuildingInformationDTO = data.rebuildReplacementBuildingInformationDTO;
    draft.withdrawDTO.errorMessage = data.errorMessage;
    (draft.realEstateProgress as OutRealEstateDTO).id = data.realEstateId;
    draft.estimationOutput.lcaOutputPlot = { ...data.outputDTO.lcaOutputPlot };
    draft.estimationOutput.lcaOutput = { ...data.outputDTO.lcaOutput };
    // draft.estimationOutput.lcaOutput = data.lcaOutput;
    for (const [key, value] of Object.entries(data.outputDTO.lcaOutput)) {
      if (value.YEAR == null) {
        const newValue = { ...value, YEAR: getSum(value) };
        draft.withdrawDTO.outputDTO.lcaOutput[key as LcaOutputType] = newValue;
      } else {
        draft.withdrawDTO.outputDTO.lcaOutput[key as LcaOutputType] = { ...value };
      }
    }
    draft.withdrawDTO.outputDTO.lcaOutput.RENEWABLE_PRIMARY_ENERGY = data.outputDTO.lcaOutput.RENEWABLE_PRIMARY_ENERGY;
    draft.withdrawDTO.outputDTO.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY =
      data.outputDTO.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY;
    draft.withdrawDTO.outputDTO.lcaOutput.TOTAL_PRIMARY_ENERGY = data.outputDTO.lcaOutput.TOTAL_PRIMARY_ENERGY;
    draft.withdrawDTO.outputDTO.lcaOutput.CO_2_EMISSION = data.outputDTO.lcaOutput.CO_2_EMISSION;
    draft.withdrawDTO.outputDTO.lcaOutput.COST = data.outputDTO.lcaOutput.COST;
    draft.withdrawDTO.outputDTO.lcaOutput.RER = data.outputDTO.lcaOutput.RER;
    draft.withdrawDTO.outputDTO.lcaOutput.EPB_USES_OF_ELECTRICITY = data.outputDTO.lcaOutput.EPB_USES_OF_ELECTRICITY;
    draft.withdrawDTO.outputDTO.lcaOutput.PRODUCED_ELECTRICITY = data.outputDTO.lcaOutput.PRODUCED_ELECTRICITY;
    draft.withdrawDTO.outputDTO.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY =
      data.outputDTO.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY;
    draft.withdrawDTO.outputDTO.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING =
      data.outputDTO.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING;
    draft.withdrawDTO.outputDTO.lcaOutput.GRID_EXPORTED = data.outputDTO.lcaOutput.GRID_EXPORTED;
    draft.withdrawDTO.outputDTO.lcaOutput.CONTRIBUTION_BY_BATTERY = data.outputDTO.lcaOutput.CONTRIBUTION_BY_BATTERY;
    draft.withdrawDTO.outputDTO.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION =
      data.outputDTO.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION;
    draft.withdrawDTO.outputDTO.lcaOutput.GRID_DELIVERED_ELECTRICITY = {
      ...data.outputDTO.lcaOutput.GRID_DELIVERED_ELECTRICITY,
      YEAR: getSum(data.outputDTO.lcaOutput.GRID_DELIVERED_ELECTRICITY!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.CONTRIBUTION = data.outputDTO.lcaOutput.CONTRIBUTION;
    draft.withdrawDTO.outputDTO.lcaOutput.FRACTION_CONTRIBUTION = data.outputDTO.lcaOutput.FRACTION_CONTRIBUTION;
    draft.withdrawDTO.outputDTO.lcaOutput.DHW = {
      ...data.outputDTO.lcaOutput.DHW,
      YEAR: getSum(data.outputDTO.lcaOutput.DHW!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.LIGHTING = {
      ...data.outputDTO.lcaOutput.LIGHTING,
      YEAR: getSum(data.outputDTO.lcaOutput.LIGHTING!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.APPLIANCES = {
      ...data.outputDTO.lcaOutput.APPLIANCES,
      YEAR: getSum(data.outputDTO.lcaOutput.APPLIANCES!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.TRANSPORT = {
      ...data.outputDTO.lcaOutput.TRANSPORT,
      YEAR: getSum(data.outputDTO.lcaOutput.TRANSPORT!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.ENERGY_SUM = {
      ...data.outputDTO.lcaOutput.ENERGY_SUM,
      YEAR: getSum(data.outputDTO.lcaOutput.ENERGY_SUM!),
    };
    draft.withdrawDTO.outputDTO.lcaOutput.NON_EPB = data.outputDTO.lcaOutput.NON_EPB;
    draft.withdrawDTO.outputDTO.lcaOutput.SOLAR_PRODUCIBILITY = data.outputDTO.lcaOutput.SOLAR_PRODUCIBILITY;
    draft.withdrawDTO.outputDTO.lcaOutput.PV = data.outputDTO.lcaOutput.PV;
    // }
    draft.greyEnergy = aggregateGreyEnergyData(data.outputDTO.lcaOutput);
    // draft.status = CALCULATION_STATE.FINAL_RESULT_LOADED;
  }),

  immerOn(RealEstateActions.estimateLCACostsError, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.ERROR;
    draft.estimationOutput = {} as RebuildEstimationOutputDTO;
    draft.withdrawDTO = {} as WithdrawDTO;
    // Sentry.configureScope((scope) => {
    //     const calcObject = JSON.stringify(realEstateContainerCopy);
    //     scope.addAttachment({
    //       filename: realEstateContainerCopy.metaData.name + '-' + new Date() + '.json',
    //       data: calcObject,
    //     });
    //   });
  }),

  immerOn(RealEstateActions.estimateLCAEnergyNeeds, (draft: RealEstateState, data) => {
    if (draft.status != CALCULATION_STATE.CALCULATING && draft.status != CALCULATION_STATE.FINAL_RESULT_LOADED) {
      draft.status = CALCULATION_STATE.CALLING;
      draft.realEstateProgress = { ...data.data };
    }
  }),
  immerOn(RealEstateActions.estimateLCAEnergyNeedsFailure, (draft: RealEstateState, data) => {
    //we can handle error here.
    // draft.status = CALCULATION_STATE.ERROR;
  }),
  immerOn(RealEstateActions.estimateLCAEnergyNeedsSuccess, (draft: RealEstateState, data) => {
    draft.status = CALCULATION_STATE.READY;
    // console.warn('EstimateLCAEnergyNeedsSuccess', data);
    if (draft.estimationOutput == null) {
      draft.estimationOutput = { lcaOutput: {} } as RebuildEstimationOutputDTO;
    }
    draft.estimationOutput.lcaOutput = { ...data.lcaOutput };

    if (data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING && data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR) {
      draft.realEstateProgress.energy.current.annualHeatingNeedsPlaceholder = Math.round(
        data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR,
      );
    }
    if (data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING && data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING.YEAR) {
      draft.realEstateProgress.energy.current.annualCoolingNeedsPlaceholder = Math.round(
        data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_COOLING.YEAR,
      );
    }
    if (
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION &&
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION.YEAR
    ) {
      //Sum up all values inside the following object data.lcaOutput.ANNUAL_LATENT_ENERGY_NEEDS_FOR_DE_HUMIDIFICATION and round the value
      draft.realEstateProgress.energy.current.annualDehumidificationNeedsPlaceholder = Math.round(
        // Object.values(data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION).reduce((a, b) => a + b, 0),
        // );
        data.lcaOutput.MONTHLY_LATENT_ENERGY_NEEDS_FOR_DEHUMIDIFICATION.YEAR,
      );
    }
    if (
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION &&
      data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION.YEAR
    ) {
      draft.realEstateProgress.energy.current.annualHumidificationNeedsPlaceholder = Math.round(
        // Object.values(data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION).reduce((a, b) => a + b, 0),
        data.lcaOutput.MONTHLY_LATENT_ENERGY_NEED_FOR_HUMIDIFICATION.YEAR,
      );
    }
  }),

  immerOn(RealEstateActions.estimateLCAEnergyNeedsAndPerformance, (draft: RealEstateState, data) => {
    if (draft.status != CALCULATION_STATE.CALCULATING && draft.status != CALCULATION_STATE.FINAL_RESULT_LOADED) {
      draft.status = CALCULATION_STATE.CALLING;
      draft.realEstateProgress = { ...data.data };
    }
  }),
  immerOn(
    RealEstateActions.estimateLCAEnergyNeedsAndPerformanceSuccess,
    (draft: RealEstateState, data: RebuildEstimationOutputDTO) => {
      if (draft.status != CALCULATION_STATE.CALCULATING && draft.status != CALCULATION_STATE.FINAL_RESULT_LOADED) {
        draft.status = CALCULATION_STATE.READY;
        // draft.realEstateProgress = { ...data.data };

        //only write the data to the inner fields of estimateOutput if inner object has values loop through the object and check if there are values
        if (draft.estimationOutput == null) {
          draft.estimationOutput = data;
        } else {
          draft.estimationOutput.lcaOutputPlot = data.lcaOutputPlot;
          draft.estimationOutput.lcaOutput.RENEWABLE_PRIMARY_ENERGY = data.lcaOutput.RENEWABLE_PRIMARY_ENERGY;
          draft.estimationOutput.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY = data.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY;
          draft.estimationOutput.lcaOutput.TOTAL_PRIMARY_ENERGY = data.lcaOutput.TOTAL_PRIMARY_ENERGY;
          draft.estimationOutput.lcaOutput.CO_2_EMISSION = data.lcaOutput.CO_2_EMISSION;
          draft.estimationOutput.lcaOutput.COST = data.lcaOutput.COST;
          draft.estimationOutput.lcaOutput.RER = data.lcaOutput.RER;
          draft.estimationOutput.lcaOutput.EPB_USES_OF_ELECTRICITY = data.lcaOutput.EPB_USES_OF_ELECTRICITY;
          draft.estimationOutput.lcaOutput.PRODUCED_ELECTRICITY = data.lcaOutput.PRODUCED_ELECTRICITY;
          draft.estimationOutput.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY =
            data.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY;
          draft.estimationOutput.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING =
            data.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING;
          draft.estimationOutput.lcaOutput.GRID_EXPORTED = data.lcaOutput.GRID_EXPORTED;
          draft.estimationOutput.lcaOutput.CONTRIBUTION_BY_BATTERY = data.lcaOutput.CONTRIBUTION_BY_BATTERY;
          draft.estimationOutput.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION =
            data.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION;
          draft.estimationOutput.lcaOutput.GRID_DELIVERED_ELECTRICITY = {
            ...data.lcaOutput.GRID_DELIVERED_ELECTRICITY,
            YEAR: getSum(data.lcaOutput.GRID_DELIVERED_ELECTRICITY!),
          };
          draft.estimationOutput.lcaOutput.CONTRIBUTION = data.lcaOutput.CONTRIBUTION;
          draft.estimationOutput.lcaOutput.FRACTION_CONTRIBUTION = data.lcaOutput.FRACTION_CONTRIBUTION;
          draft.estimationOutput.lcaOutput.DHW = {
            ...data.lcaOutput.DHW,
            YEAR: getSum(data.lcaOutput.DHW!),
          };
          draft.estimationOutput.lcaOutput.LIGHTING = {
            ...data.lcaOutput.LIGHTING,
            YEAR: getSum(data.lcaOutput.LIGHTING!),
          };
          draft.estimationOutput.lcaOutput.APPLIANCES = {
            ...data.lcaOutput.APPLIANCES,
            YEAR: getSum(data.lcaOutput.APPLIANCES!),
          };
          draft.estimationOutput.lcaOutput.TRANSPORT = {
            ...data.lcaOutput.TRANSPORT,
            YEAR: getSum(data.lcaOutput.TRANSPORT!),
          };
          draft.estimationOutput.lcaOutput.ENERGY_SUM = {
            ...data.lcaOutput.ENERGY_SUM,
            YEAR: getSum(data.lcaOutput.ENERGY_SUM!),
          };
          draft.estimationOutput.lcaOutput.NON_EPB = data.lcaOutput.NON_EPB;
          draft.estimationOutput.lcaOutput.SOLAR_PRODUCIBILITY = data.lcaOutput.SOLAR_PRODUCIBILITY;
          draft.estimationOutput.lcaOutput.PV = data.lcaOutput.PV;
          // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING =
          //   data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING;
          // if (
          //   data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING &&
          //   draft.estimationOutput.lcaOutput &&
          //   draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING
          // ) {
          //   // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR = 0;
          //   const year = Object.values(data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING).reduce((a, b) => a + b, 0);
          //   // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR = year;
          //   console.log(year);
          // }
        }

        if (data.lcaOutput.TRANSPORT) {
          draft.realEstateProgress.energy.current.annualTransportNeedsPlaceholder = Math.round(
            Object.values(data.lcaOutput.TRANSPORT).reduce((a, b) => a + b, 0),
          );
        }

        if (data.lcaOutput.LIGHTING) {
          draft.realEstateProgress.energy.current.annualLightningNeedsPlaceholder = Math.round(
            Object.values(data.lcaOutput.LIGHTING).reduce((a, b) => a + b, 0),
          );
        }

        if (data.lcaOutput.APPLIANCES) {
          draft.realEstateProgress.energy.current.annualApplianceNeedsPlaceholder = Math.round(
            Object.values(data.lcaOutput.APPLIANCES).reduce((a, b) => a + b, 0),
          );
        }
        if (data.lcaOutput.DHW) {
          draft.realEstateProgress.energy.current.annualDhwNeedsPlaceholder = Math.round(
            Object.values(data.lcaOutput.DHW).reduce((a, b) => a + b, 0),
          );
        }
        if (data.lcaOutput.PV) {
          draft.realEstateProgress.energy.current.annualPvRoofProduction = Math.round(
            Object.values(data.lcaOutput.PV).reduce((a, b) => a + b, 0),
          );
        }
        //Currently we don't calculate anything here. But this might change in the future.
        if (data.lcaOutput.NON_EPB) {
          draft.realEstateProgress.energy.current.annualNonEPBNeedsPlaceholder = Math.round(
            Object.values(data.lcaOutput.NON_EPB).reduce((a, b) => a + b, 0),
          );
        }
      }
    },
  ),

  immerOn(RealEstateActions.estimateLCAEnergyPerformance, (draft: RealEstateState, data) => {
    if (draft.status != CALCULATION_STATE.CALCULATING && draft.status != CALCULATION_STATE.FINAL_RESULT_LOADED) {
      draft.status = CALCULATION_STATE.CALLING;
      draft.realEstateProgress = { ...data.data };
    }
  }),
  immerOn(
    RealEstateActions.estimateLCAEnergyPerformanceSuccess,
    (draft: RealEstateState, data: RebuildEstimationOutputDTO) => {
      draft.status = CALCULATION_STATE.READY;
      //only write the data to the inner fields of estimateOutput if inner object has values loop through the object and check if there are values
      if (draft.estimationOutput == null) {
        draft.estimationOutput = data;
      } else {
        draft.estimationOutput.lcaOutputPlot = data.lcaOutputPlot;
        draft.estimationOutput.lcaOutput.RENEWABLE_PRIMARY_ENERGY = data.lcaOutput.RENEWABLE_PRIMARY_ENERGY;
        draft.estimationOutput.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY = data.lcaOutput.NON_RENEWABLE_PRIMARY_ENERGY;
        draft.estimationOutput.lcaOutput.TOTAL_PRIMARY_ENERGY = data.lcaOutput.TOTAL_PRIMARY_ENERGY;
        draft.estimationOutput.lcaOutput.CO_2_EMISSION = data.lcaOutput.CO_2_EMISSION;
        draft.estimationOutput.lcaOutput.COST = data.lcaOutput.COST;
        draft.estimationOutput.lcaOutput.RER = data.lcaOutput.RER;
        draft.estimationOutput.lcaOutput.EPB_USES_OF_ELECTRICITY = data.lcaOutput.EPB_USES_OF_ELECTRICITY;
        draft.estimationOutput.lcaOutput.PRODUCED_ELECTRICITY = data.lcaOutput.PRODUCED_ELECTRICITY;
        draft.estimationOutput.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY =
          data.lcaOutput.EPB_PRODUCED_AND_USED_ELECTRICITY;
        draft.estimationOutput.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING =
          data.lcaOutput.ELECTRICITY_USED_FOR_BATTERY_LOADING;
        draft.estimationOutput.lcaOutput.GRID_EXPORTED = data.lcaOutput.GRID_EXPORTED;
        draft.estimationOutput.lcaOutput.CONTRIBUTION_BY_BATTERY = data.lcaOutput.CONTRIBUTION_BY_BATTERY;
        draft.estimationOutput.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION =
          data.lcaOutput.BATTERY_CHARGE_AFTER_CONTRIBUTION;
        draft.estimationOutput.lcaOutput.GRID_DELIVERED_ELECTRICITY = {
          ...data.lcaOutput.GRID_DELIVERED_ELECTRICITY,
          YEAR: getSum(data.lcaOutput.GRID_DELIVERED_ELECTRICITY!),
        };
        draft.estimationOutput.lcaOutput.CONTRIBUTION = data.lcaOutput.CONTRIBUTION;
        draft.estimationOutput.lcaOutput.FRACTION_CONTRIBUTION = data.lcaOutput.FRACTION_CONTRIBUTION;
        draft.estimationOutput.lcaOutput.DHW = {
          ...data.lcaOutput.DHW,
          YEAR: getSum(data.lcaOutput.DHW!),
        };
        draft.estimationOutput.lcaOutput.LIGHTING = {
          ...data.lcaOutput.LIGHTING,
          YEAR: getSum(data.lcaOutput.LIGHTING!),
        };
        draft.estimationOutput.lcaOutput.APPLIANCES = {
          ...data.lcaOutput.APPLIANCES,
          YEAR: getSum(data.lcaOutput.APPLIANCES!),
        };
        draft.estimationOutput.lcaOutput.TRANSPORT = {
          ...data.lcaOutput.TRANSPORT,
          YEAR: getSum(data.lcaOutput.TRANSPORT!),
        };
        draft.estimationOutput.lcaOutput.ENERGY_SUM = {
          ...data.lcaOutput.ENERGY_SUM,
          YEAR: getSum(data.lcaOutput.ENERGY_SUM!),
        };
        draft.estimationOutput.lcaOutput.NON_EPB = data.lcaOutput.NON_EPB;
        draft.estimationOutput.lcaOutput.SOLAR_PRODUCIBILITY = data.lcaOutput.SOLAR_PRODUCIBILITY;
        draft.estimationOutput.lcaOutput.PV = data.lcaOutput.PV;
        // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING =
        //   data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING;
        // if (
        //   data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING &&
        //   draft.estimationOutput.lcaOutput &&
        //   draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING
        // ) {
        //   // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR = 0;
        //   const year = Object.values(data.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING).reduce((a, b) => a + b, 0);
        //   // draft.estimationOutput.lcaOutput.SENSIBLE_ENERGY_NEED_FOR_HEATING.YEAR = year;
        //   console.log(year);
        // }
      }

      if (data.lcaOutput.TRANSPORT) {
        draft.initialRealEstate.energy.current.annualTransportNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.TRANSPORT).reduce((a, b) => a + b, 0),
        );
      }

      if (data.lcaOutput.LIGHTING) {
        draft.initialRealEstate.energy.current.annualLightningNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.LIGHTING).reduce((a, b) => a + b, 0),
        );
      }

      if (data.lcaOutput.APPLIANCES) {
        draft.initialRealEstate.energy.current.annualApplianceNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.APPLIANCES).reduce((a, b) => a + b, 0),
        );
      }
      if (data.lcaOutput.DHW) {
        draft.initialRealEstate.energy.current.annualDhwNeedsPlaceholder = Math.round(
          Object.values(data.lcaOutput.DHW).reduce((a, b) => a + b, 0),
        );
      }
      if (data.lcaOutput.PV) {
        draft.initialRealEstate.energy.current.annualPvRoofProduction = Math.round(
          Object.values(data.lcaOutput.PV).reduce((a, b) => a + b, 0),
        );
      }
    },
  ),
  immerOn(RealEstateActions.getLCAPlotCRREMCO2Success, (draft: RealEstateState, data) => {
    // blobToBase64(draft).then((base64) => {
    // console.log(base64);
    // draft.plots['CRREM_CO2'] = base64;
    // return RealEstateActions.getLCAPlotCRREMCO2Success(ret);
    // });
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.CRREM_CO2 = data.base64;
  }),
  immerOn(RealEstateActions.getLCAPlotCRREMNonRenewableEnergySuccess, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.CRREM_NRP = data.base64;
  }),
  immerOn(RealEstateActions.getLCAPlotCRREMNonRenewableEnergyError, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.CRREM_NRP = null;
  }),
  immerOn(RealEstateActions.getLCAPlotAnnualEnergySuccess, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.ANNUAL_ENERGY = data.base64;
  }),
  immerOn(RealEstateActions.getLCAPlotAnnualEnergyError, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.ANNUAL_ENERGY = null;
  }),
  immerOn(RealEstateActions.getLCAPlotGEAKSuccess, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.GEAK = data.base64;
  }),
  immerOn(RealEstateActions.getLCAPlotGEAKError, (draft: RealEstateState, data) => {
    if (draft.plots === undefined) {
      draft.plots = { CRREM_CO2: null, CRREM_NRP: null, ANNUAL_ENERGY: null, GEAK: null };
    }
    draft.plots.GEAK = null;
  }),
  immerOn(RealEstateActions.estimateLCAGreyEnergySuccess, (draft: RealEstateState, data) => {
    // console.log('grey Energy', data);
    //foreach field in data.lcaOutput add it to the draft.estimationOutput.lcaOutput if it isn't present
    //if it is present add the values to the existing values

    // { [P in LcaOutputType]?: { [P in LcaValueType]?: number } }
    for (const [key, value] of Object.entries(data)) {
      // if (
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY' ||
      //   key === 'ENERGY_SUM' ||
      //   key === 'NON_EPB' ||
      //   key === 'SOLAR_PRODUCIBILITY'
      // ) {
      draft.estimationOutput.lcaOutput[key as LcaOutputType] = value;
      // } else {
      //   console.log(`Key is: ${key}, Value is: ${value}`);
      // }
    }

    draft.greyEnergy = aggregateGreyEnergyData(data.lcaOutput);
  }),
);

export const realEstatesFeature = createFeature({
  name: realEstatesFeatureKey,
  reducer,
  // extraSelectors: ({ selectPresets, selectRealEstateProgress }) => ({
  //   selectPresetsWithRealEstate: createSelector(selectPresets, selectRealEstateProgress, (query, realEstate) => {
  //     {
  //       realEstate:realEstate,
  //         query:query,
  //     }
  //   })
  // }),

  extraSelectors: ({ selectRealEstateProgress, selectPresets, selectStatus }) => {
    const selectPresetsWithRealEstate = createSelector(
      selectRealEstateProgress,
      selectPresets,
      (realEstate, presets) => {
        return { realEstate: realEstate, preset: presets };
      },
    );
    const selectRealEstateWithStatus = createSelector(
      selectRealEstateProgress,
      selectStatus,
      (selectRealEstateProgress, selectStatus) => ({ realEstate: selectRealEstateProgress, status: selectStatus }),
    );
    return { selectPresetsWithRealEstate, selectRealEstateWithStatus };
  },
});
// export const selectRealEstateWithStatus = createSelector(
//   realEstatesFeature.selectRealEstateProgress,
//   realEstatesFeature.selectStatus,
//   (selectRealEstateProgress, selectStatus) => ({ realEstate: selectRealEstateProgress, status: selectStatus }),
// );

export function injectRealEstateFeature() {
  const store = inject(Store);
  return {
    realEstate$: store.select(realEstatesFeature.selectInitialRealEstate),
  };
}

//other way of declaring effect for easier testability.
// export const loadEmptyRealEstateDto$ = createEffect(
//   (actions$ = inject(Actions), store = inject(Store), http = inject(HttpClient)) => {
//     // const http = inject(HttpClient);
//     return actions$.pipe(
//       ofType(RealEstateActions.getEmptyRealEstate),
//       withLatestFrom(store.select(realEstatesFeature.selectRealEstate)),
//       concatMap(([request, filters]) =>
//         http.get(`${environment.serverUrl}/api/rebuild_estate/empty`).pipe(
//           map(
//             (realestate: any) => {
//               realestate.metaData.withdrawalProductType = request.wpt;
//
//               realestate.metaData.tasks = request.tasks.length === 0 ? [request.wpt] : request.tasks;
//               // let clonedRealEstate = _.cloneDeep(realestate);
//               this.prepareRealEstateEmptyObject(realestate, request.wpt);
//               // console.error('prepareRealEstateEmptyObject: ' + JSON.stringify(clonedRealEstate));
//               return RealEstateActions.loadRealEstateSuccess(realestate);
//             },
//             catchError((error) => of(RealEstateActions.loadRealEstateFailure(error))),
//             // (),
//           ),
//         ),
//       ),
//     );
//   },
//   { functional: true },
// );
